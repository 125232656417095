import React, { useState, useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { HalfTimeTable } from "../pages/HalfTime/HalfTimeTable";

const Dashboard = () => {

    return (
        <div>
            <h1>Webet</h1>
            <HalfTimeTable /> 
        </div>
    );
}

export default Dashboard;