import React, { useState, useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box } from '@mui/material';
import { makeRequest } from '../../api';
import { Options } from "../../interfaces/Options";
import { Match } from "../../interfaces/Match";
import { HalfTimeRow } from "./HalfTimeRow";

const HalfTimeTable = () => {
    const [data, setData] = useState<Match[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const fetchData = async () => {
        const options: Options = {
            method: 'get',
        }
        const response: any = await makeRequest('api/get_sh_potential_matches', options);
        if (response.status === 200) {
            // Convert sHTimer from string to number for sorting
            const sortedData = response.data.sort((a: Match, b: Match) => {
                // Ensure sHTimer is converted to a number
                const timerA = parseFloat(a.sHTimer);
                const timerB = parseFloat(b.sHTimer);
    
                return timerB - timerA;
            });
            setData(sortedData);
        }
    };

    const handleFetchData = () => {
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredData = data.filter((match) => {
        return (
            match.matchID.toString().includes(searchTerm) ||
            match.league.toLowerCase().includes(searchTerm) ||
            match.competitors.join(' vs ').toLowerCase().includes(searchTerm) ||
            match.htTimer.toString().includes(searchTerm) ||
            match.sHTimer.toString().includes(searchTerm) ||
            match.totGoals.toString().includes(searchTerm) ||
            match.difGoals.toString().includes(searchTerm)
        );
    });

    return (
        <div>
            <h1>Partidos Primer Tiempo Terminado</h1>
            <Button
                variant="contained"
                color="primary"
                onClick={handleFetchData}
            >
                Refresh data
            </Button>
            <TextField
                label="Search"
                variant="outlined"
                onChange={handleSearchChange}
                fullWidth
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Liga</TableCell>
                            <TableCell>Partido</TableCell>
                            <TableCell>Fin PT</TableCell>
                            <TableCell>Timer</TableCell>
                            <TableCell>Tot Goles</TableCell>
                            <TableCell>Diff Goles</TableCell>
                            <TableCell>Under</TableCell>
                            <TableCell>Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((match: Match) => (
                            <HalfTimeRow key={match.matchID} match={match} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export { HalfTimeTable };