import { TableRow, TableCell } from '@mui/material';
import { Match } from "../../interfaces/Match";

const HalfTimeRow = ({ match }: { match: Match }) => {
    const under = match.bets.find((bet) => bet.name.includes('-'));

    return (
        <TableRow key={match.matchID}>
            <TableCell>{match.matchID}</TableCell>
            <TableCell>{match.league}</TableCell>
            <TableCell>{match.competitors.join(' vs ')}</TableCell>
            <TableCell>{match.htTimer}</TableCell>
            <TableCell>{match.sHTimer}</TableCell>
            <TableCell>{match.totGoals}</TableCell>
            <TableCell>{match.difGoals}</TableCell>
            <TableCell>
                {under ? under.rate : 'N/A'}
            </TableCell>
            <TableCell>
                <a href={match.link} target="_blank" rel="noopener noreferrer">
                    Open Match
                </a>
            </TableCell>
        </TableRow>
    );
};

export { HalfTimeRow };