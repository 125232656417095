import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { backend_url as backendUrl } from './config/variables';
import { Options } from './interfaces/Options';

// // Define the interface for options
// interface Options {
//   method?: 'get' | 'post' | 'put' | 'delete' | 'patch', // You can add other HTTP methods if needed
//   data?: any,
//   headers?: Record<string, string>, // More specific type for headers
//   responseType?: ResponseType, // Properly typed responseType
// }

// The makeRequest function
const makeRequest = async (
  endpoint: string, 
  { method = 'get', data = null, headers = {}, responseType = 'json' as ResponseType }: Options = {}
): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios({
      method,
      url: `${backendUrl}/${endpoint}`,
      data,
      headers,
      responseType, // Explicit ResponseType is used here
    });

    return response;
  } catch (error: any) {
    console.error('Request Failed: ', error.response ? error.response.data : error.message);
    throw error;
  }
};

export { makeRequest };
